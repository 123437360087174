@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.partnersContainer {
  display: flex;
  width: max-content;
}

.partnersMarquee {
  display: flex;
  animation: marquee 120s linear infinite;
  width: max-content;
  padding-right: 3rem;
}
