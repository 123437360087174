@import 'constants.css';

.link {
    text-decoration: none !important;
}

.link-text{
}

.header-container {
}

.table-container {
}

.container {
    margin: 2em;
}